import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Layout } from "../components/Layout";

export default ({ data }) => {
  const { frontmatter, body, fields, excerpt } = data.mdx;


  return (
    <Layout 
      pageTitle={`${frontmatter.title} - yazılarım`}
      featuredImage={frontmatter.featuredImage.childImageSharp.sizes.originalImg}
      pageDescription={excerpt}
      isArticle={true}
      publishedDate={fields.date}
      modifiedDate={fields.date}
      path={`/blog/${fields.slug}`}
    >
      <div className="blog-post">
        <h1>{frontmatter.title}</h1>
        <div className="post-meta-data">
          <span className="post-date">{fields.date}</span>
          <span className="meta-data-seperator">&#8226;</span>
          <div className="post-tag-list">
            {frontmatter.tags.map((tag, index) => {
              const isLastTag = frontmatter.tags[index + 1] ? false : true;
              if (!isLastTag) {
                return (
                  <span>
                    <Link to={`/blog/tag/${tag}`}>{tag}</Link>,{" "}
                  </span>
                );
              } else {
                return <Link to={`/blog/tag/${tag}`}>{tag}</Link>;
              }
            })}
          </div>
        </div>
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        tags
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 1920) {
              originalImg
            }
          }
        }
      }
      fields {
        slug
        date(formatString: "MMMM DD, YYYY", locale: "tr")
      }
    }
  }
`;
